import React from 'react'
import Axios from 'axios'

import OrganizationStore from './organization'
import PageStore from './page'
import ChatStore from './chat'
import { AuthStore } from './auth'

export const BASE_URL =
  process.env.NODE_ENV === 'production' ? 'https://api.chat.gerinee.com' : 'http://localhost:3003'

Axios.defaults.baseURL = BASE_URL

Axios.defaults.withCredentials = true

export const createStore = () => {
  const organization = new OrganizationStore()
  const chat = new ChatStore()
  const page = new PageStore(organization, chat)
  const auth = new AuthStore()

  return {
    organization: organization,
    page: page,
    chat: chat,
    auth: auth,
  }
}

export type TStore = ReturnType<typeof createStore>

export const StoresContext = React.createContext<TStore | null>(null)

export const useStores = () => React.useContext(StoresContext)
