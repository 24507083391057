import React from 'react'
import { Layout, Menu } from 'antd'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import './Layout.scss'

import Summary from './pages/Summary'
import Organization from './pages/Organization'
import { useStores } from './store'
import ChatStandalone from './pages/ChatStandalone'
import PrivateRoute from './components/PrivateRoute'
import LoginPage from './pages/Login'
import ManagerOnlyPage from './pages/ManagerOnly'
import JoinPage from './pages/Join'

const { Header, Footer } = Layout

const BaseLayout = observer(() => {
  const { organization, page, auth } = useStores()!

  const onClickLogout = async () => {
    await auth.logout()
    organization.clearData()
  }

  React.useEffect(() => {
    organization.fetchGroup()
    const script = document.createElement('script')
    script.async = true
    script.src = process.env.REACT_APP_SCRIPT_SRC!
    document.head.appendChild(script)
    return () => undefined
  }, [])

  return (
    <Layout>
      <Header style={{ display: 'flex' }}>
        <Menu style={{ flex: 1 }} theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
            <Link to={`/${organization.groupID}`}>홈</Link>
          </Menu.Item>
        </Menu>
        {auth.signedIn && (
          <div className="Header-Credential">
            <a>{auth.currentUser!.sub}</a>
            <a onClick={onClickLogout}>로그아웃</a>
          </div>
        )}
      </Header>
      <Layout>
        <Switch>
          <Route path={'/login'}>
            <LoginPage />
          </Route>

          <Route path={'/join'}>
            <JoinPage />
          </Route>

          <PrivateRoute path={'/manager-only'}>
            <ManagerOnlyPage />
          </PrivateRoute>

          <PrivateRoute path={`/:group/:organization/chat`}>
            <ChatStandalone />
          </PrivateRoute>
          <PrivateRoute path={`/:group/:organization`}>
            <Organization />
          </PrivateRoute>
          <PrivateRoute exact path={`/:group`}>
            <Summary />
          </PrivateRoute>
          <PrivateRoute exact path={`/`}>
            <Redirect to={{ pathname: organization.groupID || '/manager-only' }} />
          </PrivateRoute>
        </Switch>
      </Layout>
      {/* <Footer hidden={page.standalone} className="Layout-Footer">
        채팅연구소 Copyright 2021.
      </Footer> */}
    </Layout>
  )
})

export default BaseLayout
