import { Button, Tooltip, Typography } from 'antd'
import React from 'react'
import Clipboard from 'clipboard'

import { Organization } from '../util/type'

type ChatCodeModalProps = {
  organization: Organization
}

const ChatCodeModal = ({ organization }: ChatCodeModalProps) => {
  const scriptCode = `<script src="https://cdn.gerinee.com/chat-sdk-min.js" async></script>`

  const code = `<code
  id="GG-Chat-Container"
  data-org="${organization.iframeKey}"
  data-width="300"
  data-height="600"
/>`

  const realCode = `<code id="GG-Chat-Container" data-org="${organization.iframeKey}" data-width="300" data-height="600" />`

  React.useEffect(() => {
    new Clipboard('#Button-Copy')
    ;(document.querySelector('#Chat-Code > pre') as HTMLElement)!.innerText = code
  })

  return (
    <div>
      <Typography>
        <pre>{scriptCode}</pre>
      </Typography>
      <Typography id="Chat-Code">
        <pre />
      </Typography>
      <Typography.Paragraph>위의 코드를 사이트의 HTML 영역에 붙여 넣으세요.</Typography.Paragraph>
      <Tooltip placement="bottom" title="복사되었습니다!" trigger="click">
        <Button type="primary" id="Button-Copy" data-clipboard-text={realCode}>
          복사하기
        </Button>
      </Tooltip>
    </div>
  )
}

export default ChatCodeModal
