import { makeAutoObservable, runInAction } from 'mobx'
import Axios from 'axios'
import { Organization, Group, GroupUser, BadClient } from '../util/type'
import { createOrganizationEvent } from '../util/api'
import { numberToEventType } from '../util'

class OrganizationStore {
  myGroup?: Group
  managableGroups?: GroupUser[] = []
  selectedOrganization = 0
  currentOrganization!: Organization
  currentGroup: Group | null = null

  get groupID(): string {
    return this.myGroup?.id || ''
  }

  get organizations(): Organization[] {
    return this.myGroup?.organizations || []
  }

  get currentOrganizationID() {
    return this.currentOrganization.iframeKey
  }

  selectOrganization(index: number) {
    this.selectedOrganization = index
  }

  async fetchGroup() {
    const { data } = await Axios.get(`/group-info`)

    runInAction(() => {
      this.myGroup = data.group
      this.managableGroups = data.managableGroups
    })
  }

  clearData() {
    this.myGroup = undefined
    this.managableGroups = []
  }

  async fetchOrganization(groupID: string, organizationID: string) {
    const { data } = await Axios.get(`/${groupID}/${organizationID}`)
    runInAction(() => {
      this.currentOrganization = data.organization
      this.currentGroup =
        this.myGroup ||
        this.managableGroups?.map((g) => g.group).find((g) => g.id === groupID) ||
        null
    })
  }

  async updateSettings(settings: any) {
    if (!this.groupID) {
      alert('권한이 없습니다.')
      return
    }

    const { data } = await Axios.post(`/${this.groupID}/${this.currentOrganizationID}`, settings)

    runInAction(() => {
      this.currentOrganization = data.status
    })
  }

  async suspend() {
    if (!this.groupID) {
      alert('권한이 없습니다.')
      return
    }

    const event = createOrganizationEvent(
      this.currentOrganization.config.suspended ? 'SUSPEND_OFF' : 'SUSPEND_ON'
    )
    const { data } = await Axios.post(`/${this.groupID}/${this.currentOrganizationID}/event`, event)
    console.log(data)
    if (numberToEventType(data.type) === 'SUSPEND_ON') {
      this.currentOrganization.config.suspended = true
    } else {
      this.currentOrganization.config.suspended = false
    }
  }

  async sendSystemMessage(message: string) {
    if (!this.groupID) {
      alert('권한이 없습니다.')
      return
    }

    const event = createOrganizationEvent('SYSTEM_MESSAGE', { message: message })

    const { data } = await Axios.post(`/${this.groupID}/${this.currentOrganizationID}/event`, event)
    console.log(data)
  }

  async resume() {
    const event = createOrganizationEvent('SUSPEND_OFF')

    const { data } = await Axios.post(`/${this.groupID}/${this.currentOrganizationID}/event`, event)
    console.log(data)
  }

  async releaseBadClient(client: BadClient): Promise<BadClient> {
    if (!this.currentGroup) {
      console.log('no current group')
      return null
    }
    const { data } = await Axios.post(
      `/${this.currentGroup!.id}/${this.currentOrganizationID}/release-client`,
      client
    )

    return data
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export default OrganizationStore
