import ChatStore from '../store/chat'
import { MessageType, Packet } from '../util/type'

export class ChatClient {
  private ws!: WebSocket
  private chatStore!: ChatStore

  private organizationID!: string
  private channelID!: string
  private clientID!: string

  constructor(organizationID: string, chatStore: ChatStore) {
    this.organizationID = organizationID
    this.chatStore = chatStore
    this.connect()
  }

  public destroy() {
    this.ws.close()
  }

  private connect() {
    this.ws = new WebSocket('ws://localhost:8007')
    this.ws.addEventListener('message', (e) => {
      console.log(e.data)

      const data = JSON.parse(e.data) as Packet

      switch (data.type) {
        case 'SERVER_READY':
          this.clientID = data.id
          console.log(this.ASK())
          this.ws.send(this.ASK())
          break
        case 'ANSWER':
          this.ws.send(this.RECENT_MESSAGES())
          this.chatStore.setName(data.name)
          this.chatStore.setSounds(data.metadata.sounds)
          this.channelID = data.channel
          break
        case 'RECENT_MESSAGES':
          this.chatStore.setMessages(data.messages)
          break
        default:
          break
      }
    })
  }

  private ASK() {
    return this.packet({
      type: MessageType.ASK,
      id: this.clientID,
      org: this.organizationID,
    })
  }

  private RECENT_MESSAGES() {
    return this.packet({
      type: MessageType.RECENT_MESSAGES,
      id: this.clientID,
    })
  }

  private packet(data: any) {
    return JSON.stringify(data)
  }
}
