import { Button, Input, Modal, PageHeader, Switch } from 'antd'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import ChatView from '../components/ChatView'
import { useStores } from '../store'
import './Organization.scss'
import withPageLoader from './PageLoader'
import ChatCodeModal from '../components/ChatCodeModal'
import BadClientsModal from '../components/BadClientsModal'

const OrganizationPage = observer(() => {
  const history = useHistory()
  const match = useRouteMatch()
  const { organization: store } = useStores()!

  const organization = store.currentOrganization!

  const openCodeModal = () => {
    Modal.info({
      title: '구현코드 보기',
      content: <ChatCodeModal organization={organization} />,
      width: '600px',
    })
  }

  const openBadClientsModal = () => {
    Modal.info({
      title: '제재목록',
      content: <BadClientsModal organization={organization} orgStore={store} />,
      width: '1000px',
    })
  }

  const openChatView = () => {
    history.push(`/${(match.params as any).group}/${organization.iframeKey}/chat`)
  }

  const settingTitle = React.useRef<Input>(null)
  const settingAnonyKey = React.useRef<Input>(null)
  const systemMessage = React.useRef<Input>(null)

  const updateTitle = () => {
    const title = settingTitle.current?.state.value
    if (!title) {
      Modal.warn({
        title: '채팅방 이름을 입력해주세요.',
      })

      return
    }

    store.updateSettings({ title: title })
  }

  const updateAnonymousKey = () => {
    const anonymousKey = settingAnonyKey.current?.state.value
    store.updateSettings({ anonymousKey: anonymousKey || '' })
  }

  const sendSystemMessage = () => {
    const input = systemMessage.current!
    const value = input.state.value?.trim()

    if (!value) {
      return
    }

    store.sendSystemMessage(value)
    input.setValue('')
  }

  return (
    <div style={{ minWidth: 760 }}>
      <PageHeader
        className="Page-Header"
        onBack={() => history.push(`/${store.groupID}`)}
        title={organization.config.title}
        subTitle={organization.domain}
      />

      <div className="Organization-Wrapper">
        <div className="Organization-Configures">
          <div className="Organization-Info">
            <div className="Organization-Info-Row">
              <span>채팅방 이름:</span> {organization.config.title}
            </div>
            <div className="Organization-Info-Row">
              <span>연결된 도메인:</span> {organization.domain}
            </div>
            <div className="Organization-Info-Row" style={{ marginTop: 12 }}>
              <Button onClick={openCodeModal} type="primary" style={{ marginRight: 4 }}>
                구현코드 보기
              </Button>
              <Button onClick={openChatView} type="primary">
                채팅방 열기
              </Button>
            </div>
          </div>
          <h3>이벤트</h3>
          <div className="Organization-Setting">
            <div className="Organization-Setting-Row">
              <span>채팅방 얼리기</span>
              <Button type="primary" onClick={() => store.suspend()}>
                {organization.config.suspended ? '풀기' : '얼리기'}
              </Button>
            </div>
            <div className="Organization-Setting-Row">
              <span>시스템 메시지 보내기</span>
              <Input ref={systemMessage} type="text" placeholder="시스템 메시지 입력" />
              <Button type="primary" onClick={sendSystemMessage}>
                보내기
              </Button>
            </div>
          </div>
          <h3>유저 관리</h3>
          <div className="Organization-Setting">
            <div className="Organization-Setting-Row">
              <span>현재 접속한 유저</span>
              <Button type="primary">관리하기</Button>
            </div>
            <div className="Organization-Setting-Row">
              <span>제재목록</span>
              <Button type="primary" onClick={openBadClientsModal}>
                관리하기
              </Button>
            </div>
          </div>
          <h3>채팅 기록</h3>
          <div className="Organization-Setting">
            <div className="Organization-Setting-Row">
              <span>기록 열람</span>
              <Button type="primary">열람하기</Button>
            </div>
          </div>
          <h3>설정</h3>
          <div className="Organization-Setting">
            <div className="Organization-Setting-Row">
              <span>채팅방 이름 변경</span>
              <Input ref={settingTitle} placeholder={`현재: ${organization.config.title}`} />
              <Button type="primary" onClick={updateTitle}>
                변경
              </Button>
            </div>
            <div className="Organization-Setting-Row">
              <span>익명 key 변경</span>
              <Input
                ref={settingAnonyKey}
                placeholder={`현재: ${organization.config.anonymousKey || '없음'}`}
              />
              <Button type="primary" onClick={updateAnonymousKey}>
                변경
              </Button>
            </div>
            {/* <div className="Organization-Setting-Row">
              <span>디자인 변경</span>
            </div> */}
            <div className="Organization-Setting-Row">
              <span>이모지 사용 가능</span>
              <Switch
                checked={organization.config.useEmoji}
                onClick={() => store.updateSettings({ useEmoji: !organization.config.useEmoji })}
              />
            </div>
            <div className="Organization-Setting-Row">
              <span>음성첨부 사용 가능</span>
              <Switch
                checked={organization.config.useSound}
                onClick={() => store.updateSettings({ useSound: !organization.config.useSound })}
              />
            </div>
            <div className="Organization-Setting-Row">
              <span>닉네임 변경 가능</span>
              <Switch
                checked={organization.config.useNickname}
                onClick={() =>
                  store.updateSettings({ useNickname: !organization.config.useNickname })
                }
              />
            </div>
            <div className="Organization-Setting-Row">
              <span>이용 약관 적용</span>
              <Switch
                checked={organization.config.needPolicy}
                onClick={() =>
                  store.updateSettings({ needPolicy: !organization.config.needPolicy })
                }
              />
            </div>
          </div>
        </div>

        <ChatView organization={organization} />
      </div>
    </div>
  )
})

export default withPageLoader(OrganizationPage)('Organization')
