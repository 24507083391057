import React from 'react'
import { Form, Input, Button, Checkbox } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStores } from '../store'
import { RouteComponentProps, withRouter } from 'react-router-dom'

const LoginPage = observer(({ history }: RouteComponentProps) => {
  const { auth, organization } = useStores()!

  const onFinish = async (values: any) => {
    console.log('Success:', values)
    await auth.login(values)
    await organization.fetchGroup()
    if (auth.signedIn) {
      history.replace(`/${organization.groupID}`)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
      <Form
        style={{
          backgroundColor: '#ffffff',
          width: 600,
          maxWidth: '100%',
          padding: 32,
          paddingTop: 52,
          borderRadius: 6,
        }}
        name="basic"
        layout="vertical"
        labelCol={{ span: 4 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="아이디"
          name="id"
          rules={[{ required: true, message: '아이디를 입력해주세요!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="비밀번호"
          name="password"
          rules={[{ required: true, message: '비밀번호를 입력해주세요!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="autoLogin" valuePropName="checked">
          <Checkbox>자동 로그인</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            로그인
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
})

export default withRouter(LoginPage)
