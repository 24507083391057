import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Form, Input, Select } from 'antd'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const JoinPage = observer(() => {
  const [form] = Form.useForm()
  const router = useHistory()

  const join = async (values: any) => {
    console.log(values)
    await axios.post('/auth/join', values)
    router.push('/login')
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <Form
        onFinish={join}
        form={form}
        style={{ border: '1px solid #ddd', background: '#fff', padding: '1rem' }}
      >
        <Form.Item label="타입" name="type" initialValue={1}>
          <Select defaultValue={1}>
            <Select.Option value={1}>관리자</Select.Option>
            <Select.Option value={2}>매니저</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="아이디" name="username" rules={[{ required: true }]}>
          <Input type="text" />
        </Form.Item>
        <Form.Item label="이름" name="name" rules={[{ required: true }]}>
          <Input type="text" />
        </Form.Item>
        <Form.Item label="비밀번호" name="password" rules={[{ required: true }]}>
          <Input type="password" />
        </Form.Item>
        <Button htmlType="submit" type="primary">
          가입 완료
        </Button>
      </Form>
    </div>
  )
})

export default JoinPage
