export type Packet = {
  type: MessageType
  [key: string]: any
}

const PageRequestTypes = ['Summary', 'Organization', 'ChatStandalone'] as const

export type PageRequest = typeof PageRequestTypes[number]

export type PageParams = {
  group?: string
  organization?: string
}

export type User = {
  id: number
  name: string
  loginID: string
  password: string
  createDate: Date
  group: Group
  participatedGroupUser: GroupUser[]
}

export type Group = {
  id: string
  owner: User
  users: GroupUser[]
  organizations: Organization[]
  create_date: Date
  update_date: Date
}

export enum GroupRole {
  Owner = 0,
  SuperUser = 1,
  Manager = 2,
  ReadOnly = 3,
}
export type GroupUser = {
  id: string
  role: GroupRole
  group: Group
  user: User
  managableOrganizations: Organization[]
}

export type Organization = {
  id: number
  name: string
  domain: string
  create_date: Date
  iframeKey: string
  config: OrganizationConfig
}

export type OrganizationConfig = {
  id: number
  title: string
  needPolicy: boolean
  useSound: boolean
  useEmoji: boolean
  useNickname: boolean
  anonymousKey: string
  suspended: boolean
}

export type BadClient = {
  id: number
  name: string
  ip: string
  create_date: Date
  update_date: Date
  banned: boolean
  muted: boolean
  organization: Organization
}

export enum MessageType {
  SERVER_READY = 'SERVER_READY',
  ROOM_INFO = 'ROOM_INFO',
  ASK = 'ASK',
  ANSWER = 'ANSWER',
  JOIN = 'JOIN',
  CHAT = 'CHAT',
  SET_GRADE = 'SET_GRADE',
  SET_NAME = 'SET_NAME',
  GLOBAL_MUTE = 'GLOBAL_MUTE',
  GLOBAL_BAN = 'GLOBAL_BAN',
  RECENT_MESSAGES = 'RECENT_MESSAGES',
  MORE_MESSAGES = 'MORE_MESSAGES',
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
  MANAGER_MESSAGE = 'MANAGER_MESSAGE',
}

export type EventOptions = {
  message?: string
}

export type UserEventOptions = {
  mute?: boolean
  ban?: boolean
  nickname?: string | null
}

export type ChatEvent<T extends EventData> = {
  type: EventType
  data: T
}

export const eventTypes = ['SUSPEND_ON', 'SUSPEND_OFF', 'SET_USER', 'SYSTEM_MESSAGE'] as const

export type EventType = typeof eventTypes[number]

export type EventData = {
  timestamp: number
}

export type OrganizationEventData = EventData & EventOptions

export type UserEventData = EventData & UserEventOptions
