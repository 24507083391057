import { Menu, Layout } from 'antd'
import { UserOutlined, LaptopOutlined } from '@ant-design/icons'

import { observer } from 'mobx-react-lite'
import React from 'react'
import { Link, Redirect, Route, RouteProps } from 'react-router-dom'
import { useStores } from '../store'

const { SubMenu } = Menu
const { Sider, Content } = Layout

type PrivateRouteProps = RouteProps

const PrivateRoute = observer(({ children, ...rest }: PrivateRouteProps) => {
  const { auth, page, organization } = useStores()!

  const onSelectMenuItem = ({ item, key, keyPath }) => {
    console.log(item, key, keyPath)
  }

  const organizations = organization.organizations
  const managableGroups = organization.managableGroups

  const isStandalone = page.standalone

  return (
    <Route
      {...rest}
      render={({ location, match }) =>
        auth.signedIn ? (
          <>
            <Sider hidden={isStandalone} breakpoint="xs" className="Layout-Aside">
              <Menu
                onSelect={onSelectMenuItem}
                mode="inline"
                defaultSelectedKeys={[match.params.group || '']}
                defaultOpenKeys={['sub2']}
                style={{ height: '100%', borderRight: 0 }}
              >
                <Menu.Item key={organization.groupID} icon={<UserOutlined />}>
                  <Link to={`/${organization.groupID}`}>내가 소유한 채팅 그룹</Link>
                </Menu.Item>
                {!!organization.myGroup && (
                  <SubMenu key="sub3" icon={<LaptopOutlined />} title="내 소유 채팅방 관리">
                    {organizations.map((org) => {
                      return (
                        <Menu.Item key={org.iframeKey}>
                          <Link to={`/${organization.groupID}/${org.iframeKey}`}>
                            {org.config.title}
                          </Link>
                        </Menu.Item>
                      )
                    })}
                  </SubMenu>
                )}
                <Menu.Divider />
                {managableGroups?.map((gu) => {
                  const group = gu.group
                  return (
                    <SubMenu key="sub2" icon={<LaptopOutlined />} title={gu.group.id}>
                      {group.organizations.map((org) => {
                        return (
                          <Menu.Item key={gu.id}>
                            <Link to={`/${group.id}/${org.iframeKey}`}>{org.config.title}</Link>
                          </Menu.Item>
                        )
                      })}
                    </SubMenu>
                  )
                })}
              </Menu>
            </Sider>
            <Content
              className={page.standalone ? 'Layout-Content Content-Standalone' : 'Layout-Content'}
            >
              {children}
            </Content>
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  )
})

export default PrivateRoute
