import { makeAutoObservable } from 'mobx'
import { Organization } from '../util/type'
import { ChatClient } from '../websocket/chat'

class ChatStore {
  organization!: Organization
  chatClient: ChatClient | null = null

  name = ''
  sounds: any[] = []
  messages: any[] = []

  connect(organizationID: string) {
    this.chatClient = new ChatClient(organizationID, this)
  }

  destroy() {
    if (this.chatClient) {
      this.chatClient.destroy()
      this.chatClient = null
    }
  }

  setName(name: string) {
    this.name = name
  }

  setSounds(sounds: any[]) {
    this.sounds = sounds
  }

  setMessages(messages: any[]) {
    this.messages = messages
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export default ChatStore
