import { Checkbox, Modal, Space, Table } from 'antd'
import React from 'react'
import useFetch, { CachePolicies } from 'use-http'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import ko from 'dayjs/locale/ko'
import { BASE_URL } from '../store'
import { BadClient, Organization } from '../util/type'
import ChatLogModal from './ChatLogModal'
import PageLoading from './PageLoading'
import { observer } from 'mobx-react-lite'
import OrganizationStore from '../store/organization'

type BadClientsModalProps = {
  organization: Organization
  orgStore: OrganizationStore
}

type FetchResult = {
  badClients: BadClient[]
}

type BadClientWithKey = BadClient & { key: number }

dayjs.extend(relativeTime)
dayjs.locale(ko)

const BadClientsModal = observer(({ organization, orgStore }: BadClientsModalProps) => {
  const { get, loading, error, data, cache } = useFetch<FetchResult>(
    `${BASE_URL}/groupID/${organization.iframeKey}/badClients`,
    { credentials: 'include', cachePolicy: CachePolicies.NO_CACHE }
  )

  const [releasedClients, updateReleasedClients] = React.useState<{ [key: string]: BadClient }>({})

  const onClickOpenChatLogButton: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    const ip = event.currentTarget.getAttribute('data-ip')

    const badClient = data?.badClients.find((c) => c.ip === ip)

    Modal.info({
      title: '채팅내역',
      content: <ChatLogModal organization={organization} badClient={badClient} />,
      width: '1100px',
    })
  }

  const onClickReleaseButton: React.MouseEventHandler<HTMLAnchorElement> = async (event) => {
    const ip = event.currentTarget.getAttribute('data-ip')

    const badClient = data?.badClients.find((c) => c.ip === ip)

    if (badClient) {
      const newBadClient = await orgStore.releaseBadClient(badClient)
      if (newBadClient) {
        updateReleasedClients({ ...releasedClients, [newBadClient.ip]: newBadClient })
      }
    }
  }

  const columns = [
    {
      title: '상태',
      dataIndex: 'muted',
      key: 'muted',
      render: (on: boolean, record: BadClientWithKey) => {
        let _on = on
        if (releasedClients[record.ip]) {
          _on = false
        }
        return <Checkbox checked={_on} />
      },
    },
    { title: '이름', dataIndex: 'name', key: 'name' },
    { title: 'IP', dataIndex: 'ip', key: 'ip' },
    {
      title: '제재 날짜',
      dataIndex: 'create_date',
      key: 'create_date',
      render: (text) => `${dayjs(text).format('YYYY-MM-DD HH시 mm분')} | ${dayjs().to(text)}`,
    },
    {
      title: '설정',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a data-ip={record.ip} onClick={onClickOpenChatLogButton}>
            내역보기
          </a>
          <a data-ip={record.ip} onClick={onClickReleaseButton}>
            풀어주기
          </a>
        </Space>
      ),
    },
  ]

  React.useEffect(() => {
    get()
  }, [])

  if (loading) {
    return <PageLoading />
  }

  if (error) {
    console.log(error)
    return <></>
  }

  const dataSource = data?.badClients.map((cl, i) => ({ key: i, ...cl })) as BadClientWithKey[]

  return (
    <div>
      {data?.badClients.length ? (
        <Table columns={columns} dataSource={dataSource}></Table>
      ) : (
        <div>제재 목록이 비어있습니다.</div>
      )}
    </div>
  )
})

export default BadClientsModal
