import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import BaseLayout from './App'
import reportWebVitals from './reportWebVitals'

import 'antd/dist/antd.css'
import './index.css'
import { createStore, StoresContext } from './store'

const store = createStore()

ReactDOM.render(
  <StoresContext.Provider value={store}>
    <BrowserRouter>
      <BaseLayout />
    </BrowserRouter>
  </StoresContext.Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
