import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import './PageLoading.scss'

const indicator = <LoadingOutlined style={{ fontSize: 32 }} spin />

const PageLoading = () => {
  return (
    <div className="Page-Loading">
      <Spin indicator={indicator} />
    </div>
  )
}

export default PageLoading
