import { makeAutoObservable, runInAction } from 'mobx'
import { PageParams, PageRequest } from '../util/type'
import ChatStore from './chat'
import OrganizationStore from './organization'

class PageStore {
  standalone = false

  async onRequest(request: PageRequest, params?: PageParams) {
    await this.organizationStore.fetchGroup()
    switch (request) {
      case 'Summary':
        this.standalone = false
        break
      case 'Organization':
        if (!params?.organization) {
          return
        }
        if (!params?.group) {
          return
        }
        this.standalone = false
        await this.organizationStore.fetchOrganization(params.group, params.organization)
        break
      case 'ChatStandalone':
        if (!params?.organization) {
          return
        }
        if (!params?.group) {
          return
        }
        this.standalone = true
        await this.organizationStore.fetchOrganization(params.group, params.organization)

        break
      default:
        break
    }
  }

  constructor(
    private readonly organizationStore: OrganizationStore,
    private readonly chatStore: ChatStore
  ) {
    makeAutoObservable(this)
  }
}

export default PageStore
