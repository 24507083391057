import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStores } from '../store'
import withPageLoader from './PageLoader'

const Summary = observer(() => {
  const { organization } = useStores()!

  const organizations = organization.organizations!

  return (
    <div>
      <pre>{JSON.stringify(organizations, null, 4)}</pre>
    </div>
  )
})

export default withPageLoader(Summary)('Summary')
