import { observer } from 'mobx-react-lite'
import React from 'react'
import ChatView from '../components/ChatView'
import { useStores } from '../store'
import withPageLoader from './PageLoader'

const ChatStandalone = observer(() => {
  const { organization: store } = useStores()!

  const organization = store.currentOrganization

  return (
    <div style={{ height: '100%' }}>
      <ChatView height="100%" width="100%" organization={organization} />
    </div>
  )
})

export default withPageLoader(ChatStandalone)('ChatStandalone')
