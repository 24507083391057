import Axios, { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import Cookies from 'js-cookie'

export class AuthStore {
  accessToken = ''

  get currentUser() {
    if (!this.accessToken) {
      return null
    }

    const jwt = jwtDecode<JwtPayload>(this.accessToken)

    return jwt
  }

  get signedIn() {
    return !!this.accessToken
  }

  async login({ id, password, autoLogin }) {
    try {
      const result = await Axios.post('/login', { id, password, autoLogin })

      const { accessToken } = result.data

      runInAction(() => {
        this.accessToken = accessToken
      })
    } catch (e) {
      const error = e as AxiosError

      if (error.isAxiosError) {
        alert(error.response?.data.message)
      }
    }
  }

  async logout() {
    try {
      await Axios.post('/logout')
      runInAction(() => {
        this.accessToken = ''
      })
    } catch (e) {
      const error = e as AxiosError

      if (error.isAxiosError) {
        alert(error.response?.data.message)
      }
    }
  }

  constructor() {
    this.accessToken = Cookies.get('gch_access_token') || ''
    makeAutoObservable(this)
  }
}
