import {
  EventType,
  ChatEvent,
  OrganizationEventData,
  UserEventData,
  UserEventOptions,
  EventOptions,
} from './type'

export const createOrganizationEvent = (
  type: EventType,
  options?: EventOptions
): ChatEvent<OrganizationEventData> => {
  return {
    type: type,
    data: {
      timestamp: Date.now(),
      ...options,
    },
  }
}

export const createUserEvent = (
  type: EventType,
  options: UserEventOptions
): ChatEvent<UserEventData> => {
  return {
    type: type,
    data: {
      timestamp: Date.now(),
      ...options,
    },
  }
}
