import { observer } from 'mobx-react-lite'
import React from 'react'
import { useParams } from 'react-router-dom'
import PageLoading from '../components/PageLoading'
import { useStores } from '../store'
import { PageRequest } from '../util/type'

const withPageLoader = (Component) => {
  return (request: PageRequest) =>
    observer(() => {
      const onRequest = () => {
        toggleLoading(true)
        page.onRequest(request, params).then(() => toggleLoading(false))
      }

      const params = useParams()
      const { page } = useStores()!
      const [loading, toggleLoading] = React.useState(true)
      React.useEffect(onRequest, [request, params])

      if (loading) {
        return <PageLoading />
      }

      return <Component />
    })
}
export default withPageLoader
