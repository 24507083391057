import React from 'react'
import useFetch, { CachePolicies } from 'use-http'
import { Checkbox, Space, Table } from 'antd'
import { BASE_URL } from '../store'
import { BadClient, Organization } from '../util/type'
import PageLoading from './PageLoading'

type ChatLogModalProps = {
  organization: Organization
  badClient?: BadClient
}

const ChatLogModal = ({ badClient, organization }: ChatLogModalProps) => {
  const { get, loading, error, data } = useFetch<any>(
    `${BASE_URL}/groupID/${organization.iframeKey}/logs`,
    {
      credentials: 'include',
      cachePolicy: CachePolicies.NO_CACHE,
    }
  )

  const columns = [
    { title: 'IP', dataIndex: 'ip', key: 'ip' },
    { title: '익명이름', dataIndex: 'anonymousName', key: 'anonymousName' },
    { title: '이름', dataIndex: 'name', key: 'name' },
    { title: '내용', dataIndex: 'body', key: 'body' },
    {
      title: '음성',
      dataIndex: 'sound',
      key: 'sound',
      render: (sound) => <Space>{sound?.name}</Space>,
    },
    {
      title: '영상',
      dataIndex: 'video',
      key: 'video',
      render: (video) => (
        <Space>
          <a href={video?.videoURL} target="_blank" rel="noreferrer">
            {video?.title.slice(0, 15)}
          </a>
        </Space>
      ),
    },
    {
      title: '시간',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => <Space>{new Date(timestamp).toLocaleString()}</Space>,
    },
    // { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: '차단',
      dataIndex: 'muted',
      key: 'muted',
      render: (muted) => <Checkbox checked={muted} />,
    },
  ]

  React.useEffect(() => {
    get(`?ip=${badClient?.ip}&limit=500`)
  }, [])

  if (loading) {
    return <PageLoading />
  }

  if (error) {
    console.log(error)
    return <></>
  }

  const dataSource = data?.logs.map((log, i) => ({ key: i, ...log })) || []

  return (
    <div>
      {dataSource.length ? (
        <Table columns={columns} dataSource={dataSource}></Table>
      ) : (
        <div>채팅 내역이 없습니다.</div>
      )}
    </div>
  )
}

export default ChatLogModal
