import React from 'react'
import { Organization } from '../util/type'

type ChatViewProps = {
  organization: Organization
  width?: number | string
  height?: number | string
}

const ChatView = ({ organization, width, height }: ChatViewProps) => {
  React.useEffect(() => {
    // @ts-ignore
    if (window.activateGRChat) {
      // @ts-ignore
      window.activateGRChat()
    }
  })

  return (
    <code
      key={organization.id}
      id="GG-Chat-Container"
      data-manage="true"
      data-manage-password="we908rwe09rmc-23c*aea"
      data-org={organization.iframeKey}
      data-width={width || 350}
      data-height={height || 600}
    />
  )
}

export default ChatView
